<template>
  <ContentWithSidebar class="blog-wrapper prefix-templates-page" v-if="user">
    <!-- blogs -->
    <b-row class="blog-list-wrapper">
      <b-col md="3" v-for="template in templates.filter((t) => t.name.toLowerCase().includes(search_query.toLowerCase())).filter((t) => !typeSelected || (t.type && typeSelected && t.type.toLowerCase() === typeSelected.toLowerCase()))" :key="template.id">
        <b-card tag="article" no-body>
          <b-link>
            <b-img :src="imageSource(template.id)" alt="Template capture" class="card-img-top template-image" />
          </b-link>
          <b-card-body>
            <b-card-title>
              <b-link class="blog-title-truncate text-body-heading template-title">{{ template.name }}</b-link>
            </b-card-title>
            <b-card-text class="blog-content-truncate">{{ template.description }}</b-card-text>
            <b-media no-body>
              <b-media-aside vertical-align="center" class="mr-50">
                <b-avatar size="24" :src="logo" />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted mr-25">by</small>
                <small class="text-body">Admin</small>
                <span class="text-muted ml-50 mr-25">|</span>
                <small class="text-muted">{{ template.created | date }}</small>
                <b-badge pill class="byte-size-project" :variant="template.typeObj.variant">{{ template.typeObj.name }}</b-badge>
              </b-media-body>
            </b-media>
            <hr />
            <div :disabled="loadingProcess" class="d-flex justify-content-between align-items-center">
              <button type="button" @click="useTemplate(template.id)" :disabled="loadingProcess" class="btn btn-primary btn-update">
                <feather-icon icon="InfoIcon" class="mr-50" />
                <span class="font-weight-bold">{{ $t('use') }}</span>
              </button>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <!--/ blogs -->

    <!-- sidebar -->
    <div slot="sidebar" class="blog-sidebar py-2 py-lg-0">
      <!-- input search -->
      <b-form-group class="blog-search">
        <b-input-group class="input-group-merge">
          <b-form-input placeholder="Search here" v-model="search_query" />
          <b-input-group-append class="cursor-pointer" is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <!--/ input search -->

      <!-- recent posts -->
      <div class="blog-recent-posts mt-3">
        <h6 class="section-label mb-75">Recent Posts</h6>
        <b-media no-body class="mt-2" v-for="template in templates.slice(0, 5)" :key="template.id">
          <b-media-aside class="mr-2">
            <b-link @click="useTemplate(template.id)">
              <b-img :src="imageSource(template.id)" alt="Template capture" width="100" rounded height="70" />
            </b-link>
          </b-media-aside>
          <b-media-body>
            <h6 class="blog-recent-post-title">
              <b-link class="text-body-heading" @click="useTemplate(template.id)">{{ template.name }}</b-link>
            </h6>
            <span class="text-muted mb-0">{{ template.created | date }}</span>
          </b-media-body>
        </b-media>
      </div>
      <!--/ recent posts -->

      <!-- categories -->
      <div class="blog-categories mt-3">
        <h6 class="section-label mb-1">Categories</h6>

        <div class="d-flex justify-content-start align-items-center mb-75" v-for="(type, index) in types" :key="index">
          <b-link @click="selectType(type.name)">
            <b-avatar rounded size="32" :variant="typeSelected === type.name ? type.activeVariant : type.variant" class="mr-75">
              <component :is="type.icon" :style="{ width: '16px', height: '16px' }" />
            </b-avatar>
          </b-link>
          <b-link @click="selectType(type.name)">
            <div class="blog-category-title text-body">{{ type.name }}</div>
          </b-link>
        </div>
      </div>
      <!--/ categories -->
    </div>
    <!--/ sidebar -->
  </ContentWithSidebar>
</template>

<script>
const dayjs = require('dayjs')
import { ECOPLOT_API } from '@/constants/urls'
import CompressMixin from '@/mixins/CompressMixin.js'
import fetch from 'node-fetch'
import ContentWithSidebar from '@/@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
const axios = require('axios')

export default {
  components: { ContentWithSidebar },
  mixins: [CompressMixin],
  filters: {
    date(value) {
      return dayjs(value).format('MMM DD, YYYY')
    },
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        if (this.$route.name !== 'templates') return
        this.getTemplates()
      },
      { immediate: true }
    )
  },
  data() {
    return {
      templates: [],
      logo: require('/static/besec.png'),
      search_query: '',
      types: [
        { name: 'Math', variant: 'light-success', activeVariant: 'success', icon: require('/static/images/templates/math.svg').default },
        { name: 'Virus', variant: 'light-danger', activeVariant: 'danger', icon: require('/static/images/templates/virus.svg').default },
        { name: 'Japan', variant: 'light-primary', activeVariant: 'primary', icon: require('/static/images/templates/japan.svg').default },
        { name: 'Typhoon', variant: 'light-info', activeVariant: 'info', icon: require('/static/images/templates/typhoon.svg').default },
      ],
      typeSelected: null,
      loadingProcess: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.ecoplot.user
    },
  },
  methods: {
    imageSource(id) {
      return `${ECOPLOT_API}/static/${id}.png`
    },
    selectType(type) {
      if (type === this.typeSelected) {
        this.typeSelected = null
      } else {
        this.typeSelected = type
      }
    },
    async getTemplates() {
      this.templates = []

      let token = localStorage.getItem('django-authentication-token')

      if (!token) return

      let response = await axios.get(`${ECOPLOT_API}/templates/`, { headers: { Authorization: `Token ${token}` } })
      for (let i = 0; i < response.data.length; i++) {
        let typeObj = this.types.find((t) => response.data[i].type && t.name.toLowerCase() === response.data[i].type.toLowerCase())
        if (!typeObj) typeObj = this.types[0]
        response.data[i].typeObj = typeObj
      }
      this.templates = response.data
    },
    async useTemplate(id) {
      if (this.loadingProcess) return
      this.loadingProcess = true
      await this.fetchMeta(id)
      await this.fetchData(id)
      this.loadingProcess = false
    },

    async fetchMeta(id) {
      let avatar = await fetch(this.imageSource(id))
      let bufferImage = Buffer.from(await avatar.arrayBuffer())
      let token = localStorage.getItem('django-authentication-token')

      if (!token) return

      let response = await axios.get(`${ECOPLOT_API}/projects/${id}/`, { headers: { Authorization: `Token ${token}` } })
      this.$store.commit('ecoplot/CHANGE_PROJECT', { project: { ...response.data, source: 'cloud', path: null } })
      this.$store.commit('ecoplot/SET_PROJECT_THUMBNAILS', bufferImage)
    },
    async fetchData(id) {
      let response = await axios.get(`${ECOPLOT_API}/file/${id}/download/`, { responseType: 'blob' })

      this.$router.push({ path: '/map' }).then(() => {
        // wait 0.5s for page transition
        setTimeout(() => {
          this.extractProject(new File([response.data], 'filename'))
        }, 500)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/@core/scss/vue/pages/page-blog.scss';
</style>

<style scoped>
.prefix-templates-page .app-logo {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}

.prefix-templates-page .template-image {
  height: 200px;
  object-fit: cover;
}

.prefix-templates-page .template-title {
  white-space: nowrap;
  text-overflow: ellipsis;
}
.byte-size-project {
  float: right;
  font-weight: 100;
}
.btn-update {
  padding: 5px;
}
.select-type-project {
  display: none !important;
}
</style>
